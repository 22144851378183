/* src/components/Projects/Projects.css */
.projects {
  background-color: var(--project-section-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.project-box {
  background-color: var(--primary-color);
  color: var(--white);
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
