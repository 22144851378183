/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 35px;
}

.logo img {
  height: 50px;
  margin-right: 0.5rem;
}

.company-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

.company-name {
  display: flex;
  text-align: center;
  text-decoration: none;
  position: relative;
}

.company-name .eam {
  color: var(--eam-color);
}

.company-name .square {
  color: var(--square-color);
}

.tagline {
  position: relative;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #717575;
  top: -5px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: #025b6d;
}

.nav-menu {
  border-radius: 20px;
  box-shadow: 0 4px 6px #025b6d33;
  display: flex;
  gap: 0.2rem;
  padding: 0 30px;
}

.nav-menu a {
  color: var(--navlink-color);
  font-weight: 600;
  overflow: hidden;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
}

.nav-menu a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: var(--navlink-bg-hover);
  transform: translateY(100%);
  transition: transform 0.3s ease;
  z-index: -1;
}

.nav-menu a:hover::after {
  transform: translateY(0);
}

.nav-menu a.active {
  color: var(--navlink-color-active);
  border-bottom: 3px solid var(--secondary-color);
}

.nav-menu a:hover {
  color: var(--navlink-color-hover);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .logo img {
    height: 30px;
    margin-right: 0.5rem;
  }

  .tagline {
    font-size: 7px;
  }

  .menu-toggle {
    display: flex;
    align-items: center;
  }

  .nav-menu {
    max-height: 0;
    overflow: hidden;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #ffffff;
    width: 250px;
    padding: 0;
    gap: 10px;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease, padding 0.3s ease; /* Shortened transition */
  }

  .nav-menu.show {
    max-height: 500px;
    padding: 15px;
    border-radius: 0;
    border-bottom-left-radius: 10px;
  }

  .nav-menu a {
    color: #025b6c;
    padding: 5px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(var(--primary-color-rgb), 0.1);
  }

  .nav-menu a.active {
    color: var(--navlink-color-active);
    box-shadow: 0 4px 6px rgba(var(--secondary-color-rgb), 0.1);
    border-bottom: 0px;
  }

  .nav-menu a:hover {
    color: #025b6c;
  }

  .menu-open {
    color: var(--menu-btn-open-color);
  }

  .company-name {
    display: flex;
    align-items: baseline;
    text-decoration: none;
    font-size: 20px;
  }
}

/* Animations */
.nav-menu a:hover {
  animation: pulse 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 400px) {
  .tagline {
    font-size: 6px;
  }
}
