.page-section {
  padding: 40px;
}

.projects-page-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of project cards */
  gap: 20px; /* Space between cards */
}

.project-card {
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  width: calc(50% - 20px); /* 3 cards in a row */
  position: relative;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-thumbnail {
  width: 200px; /* Adjusted width */
  height: auto;
  border-radius: 10px;
  cursor: pointer;
}

.project-details {
  /* flex: 1; */
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.project-card h3 {
  font-size: 1.6rem;
  color: #025b6c;
  margin: 0 0 10px;
}

.project-card p {
  margin: 5px 0;
  color: #555;
}

.project-card button {
  background-color: #d56c50;
  color: white;
  border: none;
  padding: 10px 18px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  align-self: center;
  position: relative;
  bottom: -25px;
  border-radius: 25px;
}

.project-card button:hover {
  background-color: #b45a3f;
}

@media (max-width: 768px) {
  .project-card {
    width: 100%; /* Full-width on small screens */
    flex-direction: column; /* Stack content vertically */
  }

  .project-thumbnail {
    width: 100%; /* Full width thumbnail on small screens */
  }

  .project-card h3 {
    font-size: 0.9rem;
    color: #025b6c;
    margin: 0 0 10px;
  }

  .project-card button {
    align-self: center;
    bottom: -10px;
  }

  .thumbnail {
    width: 50px !important;
  }
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 100%;
  /* max-width: 70vw;  */
  max-height: 90vh; /* Fit the gallery to the viewport */
  overflow: hidden; /* Avoid scrollbars */
}

.modal-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.main-image {
  max-width: 90%;
  max-height: 70vh; /* Limit image size to fit the viewport */
  border-radius: 10px;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2.5rem;
  padding: 12px;
  cursor: pointer;
  z-index: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.modal .close {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 35px;
  color: white;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail {
  width: 100px;
  height: auto;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  border-radius: 5px;
}

.thumbnail.active {
  border: 2px solid #d56c50;
}

/* Image Label */
.image-label {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2px 8px;
  border-radius: 2px;
  /* margin-top: 5px; */
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--primary-color);
  font-size: 10px;
}
