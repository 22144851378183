.about {
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  color: var(--text-color);
  position: relative;
  overflow: hidden;
}

.about-content {
  background-color: rgba(255, 255, 255, 0.8); /* Light transparent background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 0 auto;
  text-align: justify;
  font-size: 20px;
}

.arrow-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-decoration: none;
}


  /* Responsive Styles */
  @media (max-width: 768px) {
    .about-content {
     
      font-size: 15px;
    }
    }
    