/* src/components/PageHeading.css */
.page-heading {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  color: #025b6c;
  text-transform: capitalize;
}

.page-heading::before,
.page-heading::after {
  content: "";
  display: inline-block;
  width: 50px;
  height: 3px;
  background-color: #d56c50; /* Orange color from your theme */
  vertical-align: middle;
  margin: 0 10px;
}

.page-heading-one {
  color: #d56c50;
  text-transform: capitalize;
}

.primary-color {
  color: #005b6c; /* Primary color */
  text-transform: capitalize;
}

.secondary-color {
  color: #f16c53; /* Secondary color */
  text-transform: capitalize;
}

/* Responsive */
@media (max-width: 768px) {
  .page-heading {
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    color: #025b6c;
    text-transform: capitalize;
  }
}

@media (max-width: 525px) {
  .page-heading {
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  .page-about .page-heading {
    font-size: 12px;
  }
}
