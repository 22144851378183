/* Styling for the parent div */
.top-banner {
  width: 100%;
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  align-items: stretch; /* Ensures all children have the same height */
  flex-wrap: wrap; /* Allows child elements to wrap on smaller screens */
  background-color: var(--primary-color); /* Light grey background for parent */
}

/* Styling for each banner item */
.banner-item {
  color: white;
  text-align: center;
  display: flex;
  align-items: center; /* Vertically centers content */
  justify-content: space-evenly; /* Horizontally centers content */
  padding: 10px; /* Optional padding for better appearance */
  /* order: 1; */
}

.banner-item .numbers-container {
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center; /* Vertically centers content */
  justify-content: space-evenly; /* Horizontally centers content */
  /* background-color: var(--secondary-color); */
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0px 3px var(--shadow-custom);
  flex-wrap: wrap;
}

/* Allocate space based on content */
.banner-item.timing {
  flex: 1 1 20%; /* Smaller width (1 item) */
  order: 1;
}

.banner-item.numbers {
  flex: 1 1 40%; /* Larger width (3 items) */
  order: 2;
}

.banner-item.email-web {
  flex: 1 1 30%; /* Intermediate width (2 items) */
  order: 3;
  flex-wrap: wrap;
}

.banner-item .link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  column-gap: 8px;
  color: var(--contact-text-color);
  font-weight: bold;
}

.banner-item .link .link-text {
  text-decoration: none !important;
}

.banner-item .link svg {
  color: var(--contact-icon-color);
}
.banner-item.email-web .link:hover,
.banner-item .numbers-container .link:hover {
  cursor: pointer;
}

.banner-item .numbers-container:hover {
  transform: scale(1.01); /* Slightly enlarge the div */
}

.banner-item.email-web .link:hover .link-text,
.banner-item .numbers-container .link:hover .link-text {
  color: var(--contact-text-color-hover);
  transform: scale(1.05);
}

.banner-item.email-web .link:hover svg,
.banner-item .numbers-container .link:hover svg {
  color: var(--contact-icon-color-hover);
  transform: scale(1.05);
}

@media (max-width: 1200px) {
  .banner-item,
  .banner-item.numbers .numbers-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    column-gap: 8px;
    row-gap: 10px;
  }

  .banner-item.numbers {
    order: 2;
    flex: 1 1 100%;
  }

  .banner-item.timing {
    order: 1;
    flex: 1 1 30%;
  }
  .banner-item.email-web {
    order: 1;
    flex: 1 1 60%;
  }
}

/* Responsive design: at 768px or below */
@media (max-width: 768px) {
  .banner-item.numbers {
    order: 2;
    flex: 1 1 100%;
  }

  .banner-item.timing {
    order: 1;
    flex: 1 1 30%;
  }
  .banner-item.email-web {
    order: 1;
    flex: 1 1 60%;
  }
}

@media (max-width: 575px) {
  .banner-item .numbers-container {
    border-radius: 20px;
  }
  .banner-item.numbers {
    order: 2;
  }

  .banner-item.timing {
    order: 1;
  }
  .banner-item.email-web {
    order: 3;
  }
}

@media (max-width: 500px) {
  .banner-item .numbers-container {
    border-radius: 5px;
  }
  .banner-item .numbers-container .link:first-child {
    order: 1; /* Full width for the first contact number */
  }
}

@media (max-width: 400px) {
  .banner-item .numbers-container {
    border-radius: 5px;
  }
}
