/* src/components/ContactIcon.css */
.contact-icon-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000; /* Ensure it's on top of other elements */
  animation: pulse .8s infinite; /* Add pulse animation */
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Scale up */
  }
  100% {
    transform: scale(1); /* Scale back to original size */
  }
}

/* Icon Styles */
.contact-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Increased size for more impact */
  height: 40px; /* Increased size for more impact */
  background-color: var(--secondary-color); /* Background color */
  border-radius: 50%; /* Circular shape */
  border: 3px solid var(--icon-border-color); /* Slightly thicker border */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3), 0 0 25px var(--secondary-color); /* Adding a glowing effect */
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

/* Hover Effect */
.contact-icon:hover {
  background-color: var(--primary-color); /* Change color on hover */
  transform: scale(1.1); /* Slightly scale up on hover */
  box-shadow: 0 0 35px var(--secondary-color), 0 0 15px rgba(0, 0, 0, 0.5); /* Glowing effect on hover */
}
