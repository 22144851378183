.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form {
  width: 80%;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-item {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.form-item label {
  font-size: 1.1rem;
  color: #025b6c;
  margin-bottom: 5px;
}

.form-item input,
.form-item textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.full-width {
  flex-basis: 100%;
}

/* Responsive */
@media (max-width: 768px) {
  .contact-page-heading {
    font-size: 2.3rem;
  }

  .contact-form {
    width: 95%;
  }

  .contact-form .form-group {
    gap: 0;
  }
}

/* Success/Error messages */
