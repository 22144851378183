/* src/components/Testimonial/Testimonial.css */
.testimonial {
  background-color: #333333;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.testimonial-box {
  background-color: var(--primary-color);
  color: var(--white);
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
