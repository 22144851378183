.hero {
  position: relative;
  min-height: 80vh;
  overflow: hidden;
}

.hero-carousel {
  width: 100%;
  height: 80vh;
}

.hero-carousel .slick-slide {
  transition: opacity 0.8s ease; /* Slower fade transition */
}

.hero-carousel .slick-slide img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}

.slick-prev,
.slick-next {
  font-size: 2rem;
  color: var(--white);
  background-color: var(--secondary-color) !important;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.slick-prev {
  left: 30px !important;
}

.slick-next {
  right: 30px !important;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Carousel captions */
.carousel-item {
  position: relative;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--caption-color);
  text-align: center;
  background-color: var(--black-transparent-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px var(--shadow-color);
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out; /* Slower transition */
}

.carousel-caption.animate {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.05); /* Scale up slightly for emphasis */
}

.carousel-caption h2,
.carousel-caption p,
.button {
  opacity: 0; /* Start invisible */
  transform: translateY(30px); /* Slide in from below */
  transition: opacity 0.8s ease-in-out 0.4s, transform 0.8s ease-in-out; /* Increased delay for smoother appearance */
}

.carousel-caption.animate h2,
.carousel-caption.animate p,
.carousel-caption.animate .button {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Slide into position */
}

.carousel-caption h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.carousel-caption p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.button {
  background-color: transparent; /* No background */
  color: var(--caption-color);
  border: 2px solid var(--secondary-color);
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 25px; /* Oval shape */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background-color: var(--secondary-color);
  color: var(--white);
}
