/* Full-screen preloader container */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  z-index: 9999;

  /* SVG Construction Background */
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22%23ffffff%22%2F%3E%3Cg%20fill%3D%22%23d4d4d4%22%3E%3Crect%20x%3D%2250%22%20y%3D%22280%22%20width%3D%22100%22%20height%3D%22100%22%2F%3E%3Crect%20x%3D%22200%22%20y%3D%22260%22%20width%3D%22100%22%20height%3D%22120%22%2F%3E%3Crect%20x%3D%22350%22%20y%3D%22240%22%20width%3D%22100%22%20height%3D%22140%22%2F%3E%3C%2Fg%3E%3Cpath%20d%3D%22M600%20160%20L700%20160%20L750%20200%20L650%20200%20Z%22%20fill%3D%22%23f4a261%22%2F%3E%3Cline%20x1%3D%22650%22%20y1%3D%22160%22%20x2%3D%22650%22%20y2%3D%22200%22%20stroke%3D%22%23000000%22%20stroke-width%3D%225%22%2F%3E%3Ccircle%20cx%3D%22650%22%20cy%3D%22210%22%20r%3D%2210%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E");
  background-size: cover;
  background-position: center;
}

/* Welcome message text */
.preloader-welcome {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase; /* Uppercase text */
}

/* Separate classes for EAM and SQUARE colors */
.preloader-welcome .eam {
  color: var(--primary-color); /* Green color for EAM */
}

.preloader-welcome .square {
  color: var(--secondary-color); /* Orange color for SQUARE */
}

/* Animation for each letter */
.preloader-welcome span {
  display: inline-block;
  animation: letterJump 1.5s ease-in-out infinite;
}

/* Adjust animation delay for each letter */
.preloader-welcome .eam span:nth-child(1) {
  animation-delay: 0s;
}
.preloader-welcome .eam span:nth-child(2) {
  animation-delay: 0.1s;
}
.preloader-welcome .eam span:nth-child(3) {
  animation-delay: 0.2s;
}
.preloader-welcome .square span:nth-child(1) {
  animation-delay: 0.3s;
}
.preloader-welcome .square span:nth-child(2) {
  animation-delay: 0.4s;
}
.preloader-welcome .square span:nth-child(3) {
  animation-delay: 0.5s;
}
.preloader-welcome .square span:nth-child(4) {
  animation-delay: 0.6s;
}
.preloader-welcome .square span:nth-child(5) {
  animation-delay: 0.7s;
}
.preloader-welcome .square span:nth-child(6) {
  animation-delay: 0.8s;
}

/* Letter jump keyframes */
@keyframes letterJump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Circular loader */
.loader {
  border: 8px solid var(--secondary-color); /* Static background part */
  border-top: 8px solid var(--primary-color); /* Moving part */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin-top: 20px;
}

/* Loader spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
