.page-margin{
    margin: 30px auto;
}


/* Responsive Styles */
@media (max-width: 768px) {
    .page-margin{
        margin: 10px auto;
    }
  }
  